import React, { useEffect, useState } from "react";
import AboutResult from "../../components/about/aboutResult/AboutResult";
import Age from "../../components/about/age/Age";
import Name from "../../components/about/name/Name";
import CurrentWeight from "../../components/about/currentWeight/CurrentWeight";
import Gender from "../../components/about/gender/Gender";
import GenderIcons from "../../components/about/gender/GenderIcons";
import GoalWeight from "../../components/about/goalWeight/GoalWeight";
import Height from "../../components/about/height/Height";
import CurrentBody from "../../components/goal/currentBody/CurrentBody";
import GoalResult from "../../components/goal/goalResult/GoalResult";
import MainGoal from "../../components/goal/mainGoal/MainGoal";
import ChairMainGoal from "../../components/goal/mainGoal/ChairMainGoal";
import Motivation from "../../components/goal/motivation/Motivation";
import TargetBody from "../../components/goal/targetBody/TargetBody";
import ActivityLevel from "../../components/lifestyle/activityLevel/ActivityLevel";
import Busy from "../../components/lifestyle/busy/Busy";
import Children from "../../components/lifestyle/children/Children";
import Diet from "../../components/lifestyle/diet/Diet";
import LifestyleResult from "../../components/lifestyle/lifestyleResult/LifestyleResult";
import DanceExperience from "../../components/plan/danceExperience/DanceExperience";
import DanceVsExercises from "../../components/plan/danceVsExercises/DanceVsExercises";
import DanceStyles from "../../components/plan/danceStyles/DanceStyles";
import FirstQuestion from "../../components/plan/firstQuestion/FirstQuestion";
import PlanResult from "../../components/plan/planResult/PlanResult";
import PlanResultProgress from "../../components/plan/planResultProgress/PlanResultProgress";
import SecondQuestion from "../../components/plan/secondQuestion/SecondQuestion";
import Session from "../../components/plan/session/Session";
import ThirdQuestion from "../../components/plan/thirdQuestion/ThirdQuestion";
import Times from "../../components/plan/times/Times";
import YogaOrFitness from "../../components/plan/yogaOrFitness/YogaOrFitness";
import CustomStepper from "../../components/UI/customStepper/CustomStepper";
import GoalBanner from "../../components/goal/goalBanner/GoalBanner";
import TargetZone from "../../components/goal/targetZone/TargetZone";
import Breakfast from "../../components/nutrition/breakfast/Breakfast";
import Lunch from "../../components/nutrition/lunch/Lunch";
import Dinner from "../../components/nutrition/dinner/Dinner";
import TypeOfDiet from "../../components/nutrition/typeOfDiet/TypeOfDiet";
import BadHabits from "../../components/nutrition/badHabits/BadHabits";
import CraveFoods from "../../components/nutrition/craveFoods/CraveFoods";
import NutritionResult from "../../components/nutrition/nutritionResult/NutritionResult";
import ChartBanner from "../../components/plan/chartBanner/ChartBanner";

import stepper from "../../core/static/stepper.json";

import styles from "./StepsComponent.module.scss";

import strings from "../../assets/localization/strings.jsx";
import CreatingPlan from "../../components/plan/creatingPlan/CreatingPlan";
import HeardAbout from "../../components/plan/heardAbout/HeardAbout";
import ChairWorkout from "../../components/plan/chairWorkout/ChairWorkout";
import WorkoutTimes from "../../components/plan/workoutTimes/WorkoutTimes";
import Benefits from "../../components/plan/benefits/Benefits";
import UsersLove from "../../components/plan/usersLove/UsersLove";
import BackgroundImageBox from "../../components/UI/backgroundImageBox/BackgroundImageBox";
import {useNavigate} from "react-router-dom";

const StepsComponent = props => {
    const { data } = props;
    const sections = data.schema !== null ? data.schema.sections : data.sections;
    const stepSections = data.nutrition !== null
                         ? sections["with_nutrition"]
                         : sections["default"];
    const [activeStep, setActiveStep] = useState(0);
    const [progress, setProgress] = useState(0);
    const [answers, setAnswers] = useState(new Map());
    const [stepComponent, setStepComponent] = useState();
    const [title, setTitle] = useState("");
    const stepsOrder = data.schema !== null ? data.schema.pages : data.pages;
    const steps = [stepper["default"]].concat(stepSections.map(key => ( stepper[key] )));
    const navigate = useNavigate();

    const activeStepsCount = stepSections.length > activeStep ? stepsOrder[stepSections[activeStep]].length - 1 : 0;

    const prevStep = () => {
        setActiveStep((prev) => prev - 1);
        setProgress(stepsOrder[stepSections[activeStep - 1]].length - 1);
    };

    const nextStep = () => {
        setProgress(0);
        setActiveStep((prev) => prev + 1);
    };

    const toPaywall = () => {
          localStorage.setItem('reach_paywall', data.campaign);
          navigate("/paywall");
    };

    function stepTitle(stepId){
        switch(stepId) {

          case "goals":
               let caseValue = stepsOrder[stepSections[activeStep]][progress];
               return (caseValue === "GenderIcons" || caseValue === "Name" || caseValue === "Age") ? strings.goal.labelIntro :  strings.goal.label
          case "about":
               return strings.about.label
          case "lifestyle":
               return strings.lifestyle.label
          case "nutrition":
               return strings.nutrition.label
          case "plan":
               return strings.plan.label
          default:
               return strings.goal.label
        }
    }

    useEffect(() => {
        let step_type = stepSections[activeStep]
        setTitle(stepTitle(step_type))
    }, [activeStep])

    const handleNext = (stepAnswerItem) => {
        if(stepAnswerItem !== null && stepAnswerItem !== undefined){

        answers.set(stepAnswerItem.get("key"), stepAnswerItem.get("value"));
        setAnswers(answers);
        }

        progress !== (activeStepsCount)
        ? setProgress((prev) => prev + 1)
        : activeStep === stepSections.length - 1
        ? toPaywall()
        : nextStep();

    };

    const handlePrev = () => {
        progress === 0 ? prevStep() : setProgress((prev) => prev - 1);
    }

    const findStep = (value, answersValue) => {
        const caseValue = stepsOrder[stepSections[activeStep]][value];
        let activeStepTitle = stepSections[activeStep]

        switch(activeStepTitle) {
            case "goals":
                switch(caseValue) {
                    case "Gender":
                          return <Gender nextStep={handleNext} cmpData={data} />;
                     case "GenderIcons":
                           return <GenderIcons nextStep={handleNext} cmpData={data} />;
                    case "Name":
                          return <Name nextStep={handleNext} data={data} />;
                    case "Age":
                          return <Age nextStep={handleNext} data={data} name={localStorage.getItem("nameInfo")}/>;
                    case "MainGoal":
                        return <MainGoal nextStep={handleNext} data={data} />;
                    case "ChairMainGoal":
                        return <ChairMainGoal nextStep={handleNext} data={data} />;
                    case "GoalBanner":
                        return <GoalBanner nextStep={handleNext} data={data} />;
                    case "Motivation":
                        return <Motivation nextStep={handleNext} data={data} />;
                    case "TargetZone":
                        return <TargetZone nextStep={handleNext} data={data} />;
                    case "CurrentBody":
                        if(localStorage.getItem("gender") === "Male"){
                         handleNext();
                         handleNext();
                        }
                        return localStorage.getItem("gender") === "Male"
                               ? <GoalResult nextStep={handleNext} data={data} />
                               : <CurrentBody nextStep={handleNext} campaignData={data} />;
                    case "TargetBody":
                          if(localStorage.getItem("gender") === "Male"){
                                             handleNext();
                           }
                        return localStorage.getItem("gender") === "Male"
                               ? <GoalResult nextStep={handleNext} data={data} />
                               : <TargetBody nextStep={handleNext} data={data} />;
                    case "GoalResult":
                        return <GoalResult nextStep={handleNext} data={data} />;
                    default:
                        return <MainGoal nextStep={handleNext} data={data} />;
                }
            case "about":
                switch(caseValue) {
                    case "Gender":
                         return <Gender nextStep={handleNext} cmpData={data} />;
                    case "GenderIcons":
                         return <GenderIcons nextStep={handleNext} cmpData={data} />;
                    case "Age":
                        return <Age nextStep={handleNext} data={data} name={localStorage.getItem("nameInfo")}/>;
                    case "Height":
                        return <Height nextStep={handleNext} data={data} />;
                    case "CurrentWeight":
                        return <CurrentWeight nextStep={handleNext} data={data} />;
                    case "GoalWeight":
                        return <GoalWeight nextStep={handleNext} data={data} />;
                    case "AboutResult":
                        return <AboutResult nextStep={handleNext} data={data} />
                    default:
                        return <Gender nextStep={handleNext} cmpData={data} />;
                }
            case "lifestyle":
                switch(caseValue) {
                    case "ActivityLevel":
                        return <ActivityLevel nextStep={handleNext} data={data} />;
                    case "Busy":
                        return <Busy nextStep={handleNext} data={data} />;
                    case "Children":
                        return <Children nextStep={handleNext} data={data} />;
                    case "Diet":
                        return <Diet nextStep={handleNext} data={data} />;
                    case "LifestyleResult":
                        return <LifestyleResult nextStep={handleNext} data={data} />;
                    default:
                        return <ActivityLevel nextStep={handleNext} data={data} />;
                }
            case "nutrition":
                switch(caseValue) {
                    case "Breakfast":
                        return <Breakfast nextStep={handleNext} />;
                    case "Lunch":
                        return <Lunch nextStep={handleNext} />;
                    case "Dinner":
                        return <Dinner nextStep={handleNext} />;
                    case "TypeOfDiet":
                        return <TypeOfDiet nextStep={handleNext} />;
                    case "BadHabits":
                        return <BadHabits nextStep={handleNext} />;
                    case "CraveFoods":
                        return <CraveFoods nextStep={handleNext} />;
                    case "NutritionResult":
                        return <NutritionResult nextStep={handleNext} />;
                    default:
                        return <Breakfast nextStep={handleNext} data={data} />;
                }
            case "plan":
                switch(caseValue) {
                    case "DanceExperience":
                        return <DanceExperience nextStep={handleNext} data={data} />;
                    case "HeardAbout":
                        return <HeardAbout nextStep={handleNext} data={data} />;
                    case "ChairWorkout":
                        return <ChairWorkout nextStep={handleNext} data={data} />;
                    case "WorkoutTimes":
                        return <WorkoutTimes nextStep={handleNext} data={data} />;
                    case "Benefits":
                        return <Benefits nextStep={handleNext} data={data} />;
                    case "UsersLove":
                        return <UsersLove nextStep={handleNext} data={data} />;
                    case "DanceStyles":
                        return <DanceStyles nextStep={handleNext} data={data} />;
                    case "Times":
                        return <Times nextStep={handleNext} data={data} />;
                    case "Session":
                        return <Session nextStep={handleNext} data={data} />;
                    case "YogaOrFitness":
                        return <YogaOrFitness nextStep={handleNext} data={data} />;
                    case "DanceVsExercises":
                        return <DanceVsExercises nextStep={handleNext} data={data} />;
                    case "FirstQuestion":
                        return <FirstQuestion nextStep={handleNext} />
                    case "SecondQuestion":
                        return <SecondQuestion nextStep={handleNext} />
                    case "ThirdQuestion":
                        return <ThirdQuestion nextStep={handleNext} />
                    case "ChartBanner":
                        return <ChartBanner nextStep={handleNext} data={data} />
                    case "CreatingPlan":
                        return <CreatingPlan nextStep={handleNext} data={data} />
                    case "PlanResult":
                        return data.planProgress === 1
                            ? <PlanResult nextStep={handleNext} answers={answersValue} data={data} />
                            : <PlanResultProgress nextStep={handleNext} answers={answersValue} data={data} />
                    case "PlanResultProgress":
                        return <PlanResultProgress nextStep={handleNext} answers={answersValue} data={data} />
                    default:
                        return <DanceExperience nextStep={handleNext} data={data} />;
                }
            default:
                return 0;
        }
    }

    useEffect(() => {
        setStepComponent(findStep(progress, answers))
    }, [progress, activeStep, answers])

    const progressInPercent = Math.floor(100 * progress / activeStepsCount)
    const stepCase = stepsOrder[stepSections[activeStep]][progress];
    const pageSettings = data.settings[`${stepCase.charAt(0).toLowerCase()}${stepCase.slice(1)}`]
    const stepsWithoutHeader = ["GoalBanner", "FirstQuestion", "SecondQuestion", "ThirdQuestion", "PlanResultProgress", "ChartBanner", "CreatingPlan"]
    const isWithoutHeader = stepsWithoutHeader.indexOf(stepCase) > -1
    const stepsWithoutStepper = ["DanceVsExercises", "ChartBanner", "PlanResultProgress", "CreatingPlan"]
    const withStepper = progress !== activeStepsCount && !(stepsWithoutStepper.indexOf(stepCase) > -1)
    const stepsWithoutBackButton = ["PlanResultProgress", "ChartBanner", "CreatingPlan"]
    const withBackButton = !(activeStep === 0 && progress === 0) && !(stepsWithoutBackButton.indexOf(stepCase) > -1)
    const withBackgroundImage = pageSettings && pageSettings.withBackgroundImage
    const mainContainerClass = `${isWithoutHeader ? styles.disablePaddings : ''} ${withBackgroundImage ? styles.withBackgroundImageContainer : ''} ${styles[`${data?.themeData?.pageContainer}Container`]} container`


    return (
        <div className={mainContainerClass}>
            {withBackgroundImage && (<BackgroundImageBox pageInfo={pageSettings} src={pageSettings} />) }
            {
                isWithoutHeader ? (
                    <>
                        {stepComponent}
                    </>
                ) : (
                    <div className={`${styles.bodyContainer} flex-column wrapper`}>
                        <div className={`${styles.titleContainer} ${ progress === activeStepsCount ? styles.disablePaddings : ''} flex-row`}>
                            {
                                withBackButton && (
                                    <img
                                        loading="lazy"
                                        className={styles.backButton}
                                        src={data?.themeData?.stepBackButton?.src}
                                        alt=""
                                        onClick={handlePrev}
                                    />
                                )
                            }
                            <h3 className={`${styles.title}`}>{stepTitle(stepSections[activeStep])}</h3>
                        </div>
                        {
                            withStepper && (
                                <CustomStepper
                                    theme={data?.themeData?.menuProgressBackground}
                                    steps={steps}
                                    current={activeStep}
                                    progress={progressInPercent}
                                />
                            )
                        }
                        {stepComponent}
                    </div>
                )
            }
        </div>
    );
}

export default StepsComponent;